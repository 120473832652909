.main {
  order: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
  z-index: 0;
}

.w-auto {
  width: min-content;
}

.w-100 {
  width: 100%;
}
