.slider {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  position: relative;
}

.slider ul {
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: calc(var(--margin-from) * 1px);
}

.swiper-animation {
  animation: swipe 1.4s ease forwards;
}

@keyframes swipe {
  0% {
    margin-top: calc(var(--margin-from) * 1px);
  }

  100% {
    margin-top: calc(var(--margin-to) * 1px);
  }
}
