.header {
  order: 0;
  flex-shrink: 0;
  width: 100%;
  z-index: 1;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.sticky {
  position: sticky;
  top: 0;
}
