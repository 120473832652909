.button {
  font-size: 14px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.64);
  margin: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.button:hover {
  background-color: #000;
}
