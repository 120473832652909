.page-container {
}

.full-container {
  width: 100%;
  box-sizing: border-box;
}
.container {
  width: 100%;
  max-width: 1080px;
}
.footer-container {
  @extend .full-container;
  padding: 56px;
  background-image: url('../../assets/img/footer-bg.png');
  background-size: cover;
  @media screen and (max-width: 750px) {
    background-image: url('../../assets/img/mobile-footer-bg.png');
    padding: 56px 24px;
  }
}

.main-page-container {
  @extend .full-container;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 750px) {
    padding-top: 80px;
    padding-bottom: 160px;
    justify-content: initial;
    height: auto;
  }
  height: calc(100vh - 84px);
  &-bg {
    position: absolute;
    bottom: 15vh;
    left: calc((100vw - 70vh) / 2);
    z-index: -1;
    background-color: rgba(93, 82, 255, 0.2);
    filter: blur(147.5px);
    width: 70vh;
    height: 70vh;
    border-radius: 50%;
    @media screen and (max-width: 750px){
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
}

.parnters-container {
  @extend .full-container;
  display: flex;
  padding: 100px 0 134px;
  align-items: center;
  background: #07041D;
  flex-direction: column;
  gap: 56px;
  @media screen and (max-width: 750px) {
    gap: 24px;
    padding: 0;
  }
}

.cards-container {
  @extend .full-container;
  padding: 100px 0;
  box-sizing: border-box;
  background: #101666;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 750px) {
    background: transparent;
    padding: 0;
  }
}
.video-container {
  @extend .full-container;
  padding: 67px 0;
  background: #07041D;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 750px) {
    padding-bottom: 0;
    padding-top: 80px;
  }
}

.animation-container {
  width: 100vw;
  overflow: hidden;
}

.temp-title {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 88px;
  line-height: 120%;
  color: #f3f0ff;
}

.shadow {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 1;
}

.shadow.top {
  top: 0;
  background: linear-gradient(rgba(7, 4, 29, 0.84), transparent);
}

.shadow.bottom {
  bottom: 0;
  background: linear-gradient(transparent, rgba(7, 4, 29, 0.84));
}

.mobile-mini-text {
  font-size: 56px;
  color: #F3F0FF;
}