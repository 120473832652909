.mobile-description-list {
  @media screen and (max-width: 750px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
 
}
.description-item {
  color: #ff5cd1;
}

.main-page-title {
  color: #F3F0FF;
  text-align: center;
}

.search-input {
  position: relative;
  width: 100%;
  max-width: 600px;
  @media screen and (max-width: 750px) {
    width: calc(100% - 48px);
  }
  &-main {
    background: rgba(243, 240, 255, 0.1);
    border: 1px solid rgba(243, 240, 255, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    padding-left: 52px;
    padding-right: calc(130px + 16px);
    font-size: 18px;
    color: #f3f0ff;
    &:focus {
      outline: 0;
      border: 1px solid rgba(243, 240, 255, 0.25);
    }
    &:hover {
      border: 1px solid rgba(243, 240, 255, 0.25);
    }
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      color: #F3F0FF;
      opacity: 0.65;
    }

    @media screen and (max-width: 750px) {
      font-size: 16px;
      height: 46px;
    }
  }
  &-button {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    user-select: none;
  }

  &-icon {
    width: 52px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video-area {
    width: 90%;
    border-radius: 16px;
    aspect-ratio: 972 / 656;
}

.video-page-title {
    color: #F3F0FF;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 750px) {
        flex-direction: column;
        padding: 0 10px;
        box-sizing: border-box;
    }
}

.video-page-subtitle {
    margin: 24px 0 66px;
    color: rgba(243, 240, 255, 0.6);
    text-align: center;
    @media screen and (max-width: 750px) {
        padding: 0 24px;
    }
}

.partners-subtitle {
    text-align: center;
    margin: 24px 0 32px;
    color: rgba(243, 240, 255, 0.6);
    @media screen and (max-width: 750px) {
        padding: 0 24px;
    }
}

.partners-title {
  color: #f3f0ff;
  text-align: center;
}

.parnter-container0 {
  animation: ani0 70s linear forwards;
}

.parnter-container1 {
  animation: ani1 70s linear forwards;
}

.parnter-container-reverse0 {
  animation: rev-ani0 70s linear forwards;
}

.parnter-container-reverse1 {
  animation: rev-ani1 70s linear forwards;
}

@keyframes ani0 {
  from {
    margin-left: 0;
  }
  to {
    transform: translate3d(calc(-360px * var(--child-count)), 0, 0);
  }
}

@keyframes ani1 {
  from {
    margin-left: 0;
  }
  to {
    transform: translate3d(calc(-360px * var(--child-count)), 0, 0);
  }
}

@keyframes rev-ani0 {
  0% {
    transform: translate3d(calc(-360px * var(--child-count)), 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rev-ani1 {
  0% {
    transform: translate3d(calc(-360px * var(--child-count)), 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.partner-item {
  padding: 32px 43px;
  background: rgba(243, 240, 255, 0.06);
  border-radius: 8px;
  user-select: none;
  & img {
    mix-blend-mode: lighten;
  }
  @media screen and (max-width: 750px) {
    padding: 16px 21.5px;
  }
}

.dropdown-item {
  width: 108px;
  height: 32px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f3f0ff;
  &:hover {
    background-color: #403bd9;
  }
}

.card-title {
  font-weight: 700;
  font-size: 38px;
  line-height: 120%;
  text-transform: capitalize;
  color: #07041D;
  @media screen and (max-width: 750px) {
    font-size: 32px;
  }
}

.card-one {
    display: flex;
    justify-content: center;
    gap: 40px 12px;
    background: #F3F0FF;
    flex-wrap: wrap;
    &>div {
        flex: 1;
    }
    @media (max-width: 750px) {
      flex-direction: column;
    }
}

.card-two {
  width: 100%;
  background: #f3f0ff;
}

.card-three {
  width: 100%;
  background: #d4fffd;
}

.card-video {
    border-radius: 16px;
    width: 504px;
    aspect-ratio: 504 / 340;
    @media screen and (max-width: 750px) {
        width: 100%;
    }
}

.footer {
  margin: 0 auto;
  &-right {
    width: 100%;
    gap: 56px 0;
    justify-content: space-between;
    @media screen and (max-width: 750px) {
      gap: 56px 98px;
      justify-content: start;
    }
  }
  &-left {
    &-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(243, 240, 255, 0.6);
      width: max-content;
    }
  }
  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 58px !important;
  }
}

.footer-nav-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #f3f0ff;
  opacity: 0.9;
  margin-bottom: 30px;
}

.footer-nav-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f3f0ff;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.card-container {
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}

.card-content {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: rgba(13, 13, 35, 0.85);
    & span {
        color: rgba(255, 92, 209, 1);
    }
    @media screen and (max-width: 750px) {
      font-size: 16px;
    }
}

.card-tab-number {
    width: 48px;
    height: 36px;
    border-radius: 18px;
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    &-1 {
        @extend .card-tab-number;
        color: #F3F0FF;
        background: #8A5AF0;
    }
    &-2 {
        @extend .card-tab-number;
        color: #F3F0FF;
        background: #FF5CD1;
    }
    &-3 {
        @extend .card-tab-number;
        color: #F3F0FF;
        background: #41CCD9;
    }
}


.mobile-nav {
  background: #07041d;
  min-height: calc(100vh - 80px);
}

.mobile-nav-item {
  padding: 24px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #f3f0ff;
  cursor: pointer;
  &:hover {
    background: rgba(243, 240, 255, 0.06);
  }
}

.mobile-nav-button {
    background: #5D52FF;
    border-radius: 24px;
    margin: 0 24px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 0;
    text-align: center;
    color: #F3F0FF;
    transition: .2s;
    margin-bottom: 38px;
    &:hover {
        opacity: 0.85;
        transform: scale(1.04);
    }

    &:active {
        transform: scale(.96);
        opacity: 1;
    }
}

.nav-wrapper {
    padding: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
