.base-title {
    font-family: 'SF Pro Display';
    font-style: normal;
}

.base-content {
    font-family: 'SF Pro Text';
    font-style: normal;
}

.normal-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    @media screen and (max-width: 750px) {
        font-size: 36px;
        padding: 0 24px;
        letter-spacing: -0.36px;
        box-sizing: border-box;
    }
}

.normal-content {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
}

.normal-subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    @media screen and (max-width: 750px) {
        font-size: 16px;
    }
}

.description-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.nav-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: rgba(243, 240, 255, 0.85);
    cursor: pointer;
    user-select: none;
    width: max-content;

    &:hover {
        color: #887AFF;
    }

    &:active {
        color: #2727B3;
    }

    &:focus {
        color: #5D52FF;
    }
}

.app-button-container {
    background: #5D52FF;
    border-radius: 24px;
    padding: 8px 32px;
    cursor: pointer;
    user-select: none;
    transition: .2s;
    .app-button {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.85);
    }

    &:hover {
        opacity: 0.85;
        transform: scale(1.04);
    }

    &:active {
        transform: scale(.96);
        opacity: 1;
    }
}

.base-card {
    width: 100%;
    border-radius: 40px;
    padding: 32px;
    box-sizing: border-box;
}


.search-button-text {
    @extend .base-title;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #F3F0FF;
    @media (max-width: 750px) {
        font-size: 16px;
    }
}

.search-button {
    width: 130px;
    height: 56px;
    background: #5D52FF;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .search-button-text;
    &:hover {
        background: #403BD9;
    }
    &:active {
        background: rgba(93, 82, 255, 0.65);
    }
    @media screen and (max-width: 750px) {
        height: 46px;
        width: 88px;
    }
}



.dropdown {
    position: relative;
    .dropdown-children {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }
    .dropdown-container {
        position: absolute;
        margin: 0;
        padding: 0;
        right: 0;
        top: 100%;
        list-style: none;
        padding: 4px 0;
        box-sizing: border-box;
        background: rgba(243, 240, 255, 0.1);
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        display: none;
    }

    &:hover .dropdown-container {
        display: revert;
        z-index: 1;
    }
}